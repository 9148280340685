import React, { Component } from 'react'
import {ReactComponent as AirbnbLogo} from './partials/brands/logotype/airbnb.svg'
import {ReactComponent as CoinbaseLogo} from './partials/brands/logotype/coinbase.svg'
import {ReactComponent as DribbbleLogo} from './partials/brands/logotype/dribbble.svg'
import {ReactComponent as InstagramLogo} from './partials/brands/logotype/instagram.svg'
import {ReactComponent as NetflixLogo} from './partials/brands/logotype/netflix.svg'
import {ReactComponent as PinterestLogo} from './partials/brands/logotype/pinterest.svg'
import {ReactComponent as Settings1} from './partials/icons/duotone-icons/general/settings-1.svg'
import {ReactComponent as MediaRepeat} from './partials/icons/duotone-icons/media/repeat.svg'
import {ReactComponent as Chat6} from './partials/icons/duotone-icons/communication/chat-6.svg'
import {ReactComponent as Circle1} from './partials/icons/duotone-icons/general/1-circle-fill.svg'
import {ReactComponent as Circle2} from './partials/icons/duotone-icons/general/2-circle-fill.svg'
import {ReactComponent as Circle3} from './partials/icons/duotone-icons/general/3-circle-fill.svg'
import {ReactComponent as Circle4} from './partials/icons/duotone-icons/general/4-circle-fill.svg'

import { SEOHeaders } from "./SeoHeaders"
import { config } from './config'

interface AppProps {

}

type AppStates = {
  error: string
  success: string
  name: string
  email: string
  comments: string
  isLoading: boolean
  displayType: string
  isMenuOpen: boolean
  message: string
}

class App extends Component <AppProps, AppStates> {
  constructor (props: AppProps) {
    super(props)
    this.state = {
      isLoading: false,
      error: '',
      success: '',
      name: '',
      email: '',
      comments: '',
      displayType: 'nodejs',
      isMenuOpen: false,
      message: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as AppStates)
  }

  handleForm = () => {
    this.setState({
      error: '',
      success: '',
      isLoading: true
    })
    fetch(
      config.app.apiUri + '/api/v1/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.state.email,
          name: this.state.name,
          comments: this.state.comments
        })
      }).then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.setState({
            email: '',
            name: '',
            comments: '',
            isLoading: false,
            message: json.message
          })
        } else {
          this.setState({
            error: json.message,
            isLoading: false
          })
        }
      })
  }

  render() {
    return (
      <div id="home">

        <SEOHeaders/>

        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container">

            <a className="navbar-brand" href="/">
              <img src="assets/img/illustrations/logo-ady-v2.png" className="navbar-brand-img" alt="..." style={{height: 35}}/>
            </a>
        
            <button className="navbar-toggler" type="button" onClick={()=>this.setState({isMenuOpen: true})}>
              <span className="navbar-toggler-icon"></span>
            </button>
        
            <div className={`collapse navbar-collapse ${this.state.isMenuOpen ? 'show' : ''}`} id="navbarCollapse">
        
              <button className="navbar-toggler" onClick={()=>this.setState({isMenuOpen: false})} type="button">
                <i className="fe fe-x"></i>
              </button>
        
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a className="nav-link" id="navbarLandings" href="#advertisers" aria-haspopup="true" aria-expanded="false">
                    Advertisers
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="navbarPages" href="#campaigns" aria-haspopup="true" aria-expanded="false">
                    Campaigns
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="navbarPages" href="#developer" aria-haspopup="true" aria-expanded="false">
                    Developers
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="navbarAccount" href="#contact" aria-haspopup="true" aria-expanded="false">
                    Contact
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="navbarDocumentation" href="https://blog.chatady.com" aria-haspopup="true" aria-expanded="false">
                    Blog
                  </a>
                </li>
              </ul>
        
              <div className="ms-auto">
                <a className="navbar-btn btn btn-primary-soft btn-xs lift me-4" href={config.app.appUri+'/sign-in'}>
                  Sign In
                </a>
                <a className="navbar-btn btn btn-xs btn-primary lift" href={config.app.appUri+'/sign-up'}>
                  Sign Up
                </a>
              </div>
        
            </div>
        
          </div>
        </nav>

        <section className="pt-4 pt-md-11 pb-10">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-5 col-lg-6 order-md-2">

                <img src="assets/img/illustrations/globe.png" className="img-fluid mw-md-150 mw-lg-120 mb-6 mb-md-0" alt="..." data-aos="fade-up" data-aos-delay="100"/>

              </div>
              <div className="col-12 col-md-7 col-lg-6 order-md-1" data-aos="fade-up">

                <h1 className="display-2 text-center text-md-start pb-6">
                  Conversational <br></br><span className="text-primary">Ad</span> Exchange
                </h1>

                <p className="text-center text-md-start text-muted mb-5 mb-lg-8">
                  Advertising should be conversational, not intrusive.
                  We transform traditional advertising into interactive dialogues, connecting advertisers and chatbot developers.
                </p>

                <div className="text-center text-md-start">
                  <a href={config.app.appUri+'/sign-up'} className="btn btn-primary btn-sm shadow lift me-5">
                    Start Here
                  </a>
                  <a href={config.app.appUri+'/sign-up'} className="btn btn-primary-soft btn-sm lift">
                    Documentation
                  </a>
                </div>

              </div>
            </div>
          </div>
        </section>
        {/*
        <section className="py-8 py-md-11 border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4" data-aos="fade-up">

                <div className="icon text-primary mb-3">
                  <Settings1/>
                </div>

                <h3>
                  Create Campaigns
                </h3>

                <p className="text-muted mb-6 mb-md-0">
                  Create and publish your campaigns across a broad range of chatbots and platforms.
                </p>

              </div>
              <div className="col-12 col-md-4" data-aos="fade-up" data-aos-delay="50">

                <div className="icon text-primary mb-3">
                  <MediaRepeat/>
                </div>

                <h3>
                  Response Metadata
                </h3>

                <p className="text-muted mb-6 mb-md-0">
                  Get responses to your campaigns condensed and available to you in analytics.
                </p>

              </div>
              <div className="col-12 col-md-4" data-aos="fade-up" data-aos-delay="100">

                <div className="icon text-primary mb-3">
                  <Codecode/>
                </div>

                <h3>
                  Integrations & LangChain
                </h3>

                <p className="text-muted mb-0">
                  For chatbot owners we provide direct and LangChain integration with detailed steps. 
                </p>

              </div>
            </div>
          </div>
        </section>
        */}
        <section className="py-6 py-md-8 border-bottom">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">

                <div className="img-fluid text-gray-600 mb-2 mb-md-0">
                  <AirbnbLogo/>
                </div>

              </div>
              <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">

                <div className="img-fluid text-gray-600 mb-2 mb-md-0">
                  <CoinbaseLogo/>
                </div>

              </div>
              <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">

                <div className="img-fluid text-gray-600 mb-2 mb-md-0">
                  <DribbbleLogo/>
                </div>

              </div>
              <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">

                <div className="img-fluid text-gray-600 mb-2 mb-md-0">
                  <InstagramLogo/>
                </div>

              </div>
              <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">

                <div className="img-fluid text-gray-600 mb-2 mb-md-0">
                  <NetflixLogo/>
                </div>

              </div>
              <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">

                <div className="img-fluid text-gray-600 mb-2 mb-md-0">
                  <PinterestLogo/>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="mb-12 pt-8 pt-md-11 bg-gradient-light-white" id="advertisers">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-7 col-lg-6" data-aos="fade-right">

                <h1 className="mb-8">
                  Advertise Your <br></br>
                  <span className="text-primary">Services, Products</span>
                </h1>

                <div className="d-flex">
                  <div className="icon text-primary">
                    <Chat6/>
                  </div>
                  <div className="ms-5">
                    <h4 className="mb-1">
                      Delivered by a chatbot
                    </h4>
                    <p className="text-muted mb-6">
                      Utilizing the personal bond between the consumer and chatbot, campaigns achieve significant engagement
                    </p>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="icon text-primary">
                    <MediaRepeat/>
                  </div>
                  <div className="ms-5">
                    <h4 className="mb-1">
                      Consumer responses
                    </h4>
                    <p className="text-muted mb-6">
                      Since communication is a two-way, we evaluate the responses and provide them processed as campaign metrics
                    </p>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="icon text-primary">
                    <Settings1/>
                  </div>
                  <div className="ms-5">
                    <h4 className="mb-1">
                      Maximized return on investment
                    </h4>
                    <p className="text-muted mb-6">
                      By employing the generalized second-price auction, we guarantee equitable market values for all participants
                    </p>
                  </div>
                </div>

              </div>
              <div className="col-12 col-md-5 col-lg-6">

                <div className="w-md-150 w-lg-130 position-relative" data-aos="fade-left">

                  <div className="img-skewed img-skewed-start">

                    <img src="assets/img/illustrations/dash.png" className="screenshot img-fluid img-skewed-item shadow" alt="..."/>

                  </div>

                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="mb-12 pt-8 pt-md-11 bg-gradient-light-white" id="campaigns">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-5 col-lg-6">
                <div className=" position-relative" data-aos="fade-left">
                  <img src="assets/img/illustrations/howitworks.svg" className="img-fluid" alt="..."/>
                </div>
              </div>
              <div className="col-12 col-md-7 col-lg-6" data-aos="fade-right">

                <h1 className="mb-8 mb-md-10 mt-8 mt-md-2">
                  How campaigns <span className="text-primary">work</span>
                </h1>

                <div className="d-flex">
                  <div className="icon text-primary">
                    <Circle1 fill='rgba(51, 94, 234, 0.15)'/>
                  </div>
                  <div className="ms-5">
                    <h4 className="mb-1">
                      Campaign trigger
                    </h4>
                    <p className="text-muted mb-6">
                      The campaign is initiated when a user's chat matches the context of the campaign trigger, which in this instance is "buying energy drink".
                    </p>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="icon text-primary">
                    <Circle2 fill='rgba(51, 94, 234, 0.15)'/>
                  </div>
                  <div className="ms-5">
                    <h4 className="mb-1">
                      Content injection
                    </h4>
                    <p className="text-muted mb-6">
                      The initial content of the campaign is incorporated into the conversation as specified by the campaign's guidelines.
                    </p>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="icon text-primary">
                    <Circle3 fill='rgba(51, 94, 234, 0.15)'/>
                  </div>
                  <div className="ms-5">
                    <h4 className="mb-1">
                      Users response
                    </h4>
                    <p className="text-muted mb-6">
                      The user's response is collected and once again compared against the predefined responses for campaign closure.
                    </p>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="icon text-primary">
                    <Circle4 fill='rgba(51, 94, 234, 0.15)'/>
                  </div>
                  <div className="ms-5">
                    <h4 className="mb-1">
                      Campaign closure
                    </h4>
                    <p className="text-muted mb-6">
                      A reply to the user's response is inserted into the conversation, and control is then returned back to the chatbot.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="py-8 pt-md-10 pb-md-10 bg-dark" id="developer">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 text-center">

                <h6 className="text-uppercase text-success fw-bold">
                  Developers
                </h6>

                <h1 className="fw-bold text-white pb-7">
                  Monetize Your Chatbot Platorm
                </h1>

              </div>
            </div>

            <div className="row">

              <div className="col-md-4 col-12">
                <p className="fs-lg text-white mb-4">
                  Ship fast with easy-to-use APIs
                </p>
                <p className="fs-lg text-gray-500 mb-7 mb-md-9">
                  With single integration we deliver global ads that will make move your monetization strategy to set and forget. 
                </p>
              </div>

              <div className="col-md-8 col-12">
                <div className='fade alert alert-light show'>
                  <ul className="nav nav-tabs mb-3">
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'nodejs' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'nodejs'})}>Nodejs</div>
                    </li>
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'python' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'python'})}>Python</div>
                    </li>
                  </ul>
                  { this.state.displayType === 'nodejs' ?
                    <>
                      <div>Install NPM Package:</div>
                      <div className='mb-3'><code>npm install --save chatady-node</code></div>
                      <div>Import and Initialize the Client:</div>
                      <div><code>{`import ChatADy from 'chatady-node';`}</code></div>
                      <div className='mb-3'><code>{`const client = ChatADy('publisherId', 'apiKey');`}</code></div>
                      <div>Retrieve Ad Content:</div>
                      <div><code>{`const response = await client.getContents('conversationId')`}</code></div>
                    </>
                  : null}
                  { this.state.displayType === 'python' ?
                    <>
                      <div>Install Python Package:</div>
                      <div className='pb-3'><code>pip install chatady</code></div>
                      <div>Import and Initialize the Client:</div>
                      <div><code>from chatady.chatady import ChatADy</code></div>
                      <div className='pb-3'><code>client = ChatADy('publisherId', 'apiKey')</code></div>
                      <div>Retrieve Ad Content:</div>
                      <div><code>{`response = client.get_contents('conversation_id')`}</code></div>
                    </>
                  : null}
                </div>
              </div>

            </div>

            {/*
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3 mb-4">

                <h4 className="fw-bold text-white mb-5">
                  Integrations
                </h4>

                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    Basic CRUD API
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    3rd party snippets
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    Slack notifications
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300 mb-6 mb-lg-0">
                    Zapier hooks
                  </p>

                </div>

              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-4">

                <h4 className="fw-bold text-white mb-5">
                  Storage
                </h4>

                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    SQL and SQLite
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    File system access
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    Infinite storage
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300 mb-6 mb-lg-0">
                    Mongo and NoSQL
                  </p>

                </div>

              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-4">

                <h4 className="fw-bold text-white mb-5">
                  Integrations
                </h4>

                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    Basic CRUD API
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    3rd party snippets
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    Slack notifications
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300 mb-6 mb-lg-0">
                    Zapier hooks
                  </p>

                </div>

              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-4">

                <h4 className="fw-bold text-white mb-5">
                  Storage
                </h4>

                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    SQL and SQLite
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    File system access
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300">
                    Infinite storage
                  </p>

                </div>
                <div className="d-flex">

                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-gray-300 mb-0">
                    Mongo and NoSQL
                  </p>

                </div>

              </div>
            </div>
            */}
          </div>
        </section>


        <section className="pt-6 pt-md-8">
          <div className="container pb-6 pb-md-8 border-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md">

                <h3 className="fw-bold mb-1">
                  Ready to join?
                </h3>

                <p className="fs-lg text-muted mb-6 mb-md-0">
                  Feel free to contact us with any questions
                </p>

              </div>
              <div className="col-12 col-md-auto">

                <a href="#contact" className="btn btn-primary-soft btn-sm me-3 lift">
                  Contact Us
                </a>

                <a href={config.app.appUri+'/sign-up'} className="btn btn-primary btn-sm lift">
                  Sign Up
                </a>

              </div>
            </div>
          </div>
        </section>


        <section className="pt-8 pt-md-11 pb-8 pb-md-10" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 text-center">

                <h2 className="fw-bold">
                  Let us hear from you directly!
                </h2>

                <p className="fs-lg text-muted mb-7 mb-md-9">
                  Contact us for any business inquiries about ChatADy and its features.
                </p>

              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-10">

                <form>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group mb-5">

                        <label className="form-label" htmlFor="contactName">
                          Full Name
                        </label>

                        <input className="form-control" value={this.state.name} name="name" onChange={this.inputChange} type="text" placeholder="Full name"/>

                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group mb-5">

                        <label className="form-label" htmlFor="contactEmail">
                          Contact Email
                        </label>

                        <input className="form-control" value={this.state.email} name="email" onChange={this.inputChange} type="email" placeholder="hello@domain.com"/>

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group mb-7 mb-md-9">

                        <label className="form-label" htmlFor="contactMessage">
                          What can we help you with?
                        </label>

                        <textarea className="form-control" value={this.state.comments} name="comments" onChange={this.inputChange} rows={5} placeholder="Tell us what we can help you with!"></textarea>

                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-auto">

                      <button type="button" onClick={()=>this.handleForm()} className="btn btn-primary lift btn-sm">
                        Send message
                      </button>

                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-auto">
                      {this.state.message ?
                        <div className="alert alert-success mt-5" role="alert">
                          {this.state.message}
                        </div>
                      :null}
                      {this.state.error ?
                        <div className="alert alert-danger mt-5" role="alert">
                          {this.state.error}
                        </div>
                      :null}
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </section>

        <footer className="py-8 py-md-11 bg-dark">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3">
        
                <img src="./assets/img/illustrations/logo-ady-white-v2.png" height="35" alt="..." className="footer-brand mb-2"/>
        
                <p className="text-gray-700 mb-4 mt-1">
                  Conversational <br></br>Ad Exchange
                </p>
        
                <ul className="list-unstyled list-inline list-social mb-6 mb-md-0">
                  <li className="list-inline-item list-social-item me-3">
                    <a href="#!" className="text-decoration-none">
                      <img src="./assets/img/icons/social/instagram.svg" className="list-social-icon" alt="..."/>
                    </a>
                  </li>
                  <li className="list-inline-item list-social-item me-3">
                    <a href="#!" className="text-decoration-none">
                      <img src="./assets/img/icons/social/facebook.svg" className="list-social-icon" alt="..."/>
                    </a>
                  </li>
                  <li className="list-inline-item list-social-item me-3">
                    <a href="#!" className="text-decoration-none">
                      <img src="./assets/img/icons/social/twitter.svg" className="list-social-icon" alt="..."/>
                    </a>
                  </li>
                  <li className="list-inline-item list-social-item">
                    <a href="#!" className="text-decoration-none">
                      <img src="./assets/img/icons/social/pinterest.svg" className="list-social-icon" alt="..."/>
                    </a>
                  </li>
                </ul>
        
              </div>
              <div className="col-6 col-md-4 col-lg-3">
        
                <h6 className="fw-bold text-uppercase text-gray-700">
                  Information
                </h6>
        
                <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                  <li className="mb-3">
                    <a href="#home" className="text-reset">
                      Welcome
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#advertisers" className="text-reset">
                      Advertisers
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#developer" className="text-reset">
                      Developer
                    </a>
                  </li>
                </ul>
        
              </div>
              <div className="col-6 col-md-4 offset-md-4 col-lg-3 offset-lg-0">
        
                <h6 className="fw-bold text-uppercase text-gray-700">
                  Quick Links
                </h6>
        
                <ul className="list-unstyled text-muted mb-0">
                  <li className="mb-3">
                    <a href={config.app.appUri} className="text-reset">
                      Platform
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href={config.app.appUri+'/sign-in'} className="text-reset">
                      Sign In
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href={config.app.appUri+'/sign-up'} className="text-reset">
                      Sign Up
                    </a>
                  </li>
                </ul>
        
              </div>
              <div className="col-6 col-md-4 col-lg-3">
        
                <h6 className="fw-bold text-uppercase text-gray-700">
                  Legal
                </h6>
        
                <ul className="list-unstyled text-muted mb-0">
                  <li className="mb-3">
                    <a href="/termsandconditions.html" className="text-reset">
                      Terms & Conditions
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#contact" className="text-reset">
                      Contact Us
                    </a>
                  </li>
                </ul>
        
              </div>
            </div>
          </div>
        </footer>

      </div>
    )
  }
}

export default App
